(function() {
    'use strict';
    window.addEventListener('load', function() {
        var forms = document.getElementsByClassName('needs-validation');
        var validation = Array.prototype.filter.call(forms, function(form) {

        //要素を取得
        const inputs = document.getElementsByTagName('input');
        const btn = document.querySelector('.btn-next');

        //初期化
        btn.classList.remove('disabled');
        btn.disabled = false;

        // submit押下のタイミングでチェック
        form.addEventListener('submit', function(event) {

            //各input要素のis-invalidクラスの有無をチェック
            Object.keys(inputs).forEach(function(key){
                if(inputs[key].classList.contains('is-invalid')){
                    event.preventDefault();
                    event.stopPropagation();
                }
            });

            // form要素全体のvalidityをチェック
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }else{
                // 連続クリック防止のためdisabledを付与
                btn.classList.add('disabled');
                btn.disabled = true;
            }

            form.classList.add('was-validated');
        }, false);

        //input入力時にチェック
        for(let i = 0; i < inputs.length; i++){
            inputs[i].addEventListener('keyup',(elm) => {
                checkRegex(elm);
            });
        }

        // inputからフォーカスを外したタイミングでチェック
        for(let i = 0; i < inputs.length; i++){
            inputs[i].addEventListener('focusout',(elm) => {
                checkRegex(elm);
            });
        }
    });
    }, false);
  })();

// inputのvalに対してpatternチェックを行いclassを追加・削除する関数
function checkRegex(elm){

    const regexName = /^([a-z0-9.]{8,30})$/
    const regexEmail = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
    const regexPassword = /^([a-zA-Z0-9-_!@]{8,})$/

    const tgt = elm.currentTarget; //input
    const tgtId = tgt.id; //id
    const tgtVal = tgt.value //val

    if(tgtId === 'name'){
        if(regexName.test(tgtVal)){
            changeToValid(tgt);
        }else{
            changeToInvalid(tgt);
        }
    }
    if(tgtId === 'email'){
        if(regexEmail.test(tgtVal)){
            changeToValid(tgt);
        }else{
            changeToInvalid(tgt);
        }
    }
    if(tgtId === 'password'){
        if(regexPassword.test(tgtVal)){
            changeToValid(tgt);
        }else{
            changeToInvalid(tgt);
        }
    }
}

// validに変更
function changeToValid(tgt){
    tgt.classList.add('is-valid');
    tgt.classList.remove('is-invalid');
    tgt.setCustomValidity(""); //inputをvalid状態に変更
}
// invalidに変更
function changeToInvalid(tgt){
    tgt.classList.add('is-invalid');
    tgt.classList.remove('is-valid');
    tgt.setCustomValidity("error"); //inputをinvalid状態に変更
}